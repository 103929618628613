import React from "react";
import Gradient from "../../assets/images/background-gradient.svg";
import "./background-gradient.css";

export default function BackgroundGradient() {
  return (
    <div>
      <img src={Gradient} className="background-gradient" alt=""></img>
    </div>
  );
}
