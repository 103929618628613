import React from "react";
import "./nav.css";
import logo from "../../assets/logo.svg";
import Button from "../button/button";
import Pulse from "../pulse/pulse";
import { Link } from "react-router-dom";

export default function Navigation() {
  return (
    <div className="navigation">
      <div className="nav-left">
        <a href="/">
          <img src={logo} alt=""></img>
        </a>
        <div className="pulse-text">
          <Pulse />
          <p>Available for freelance projects</p>
        </div>
      </div>
      <div className="nav-right">
        <ul className="nav-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/projects">All projects</Link>
          </li>
          <li>
            <Link to="/">About me</Link>
          </li>
        </ul>
        <Button
          class="button primary-button"
          link="mailto:emilyrichardsdesign@gmail.com"
          text="Let's chat"
        />
      </div>
    </div>
  );
}
