import React from "react";
import "./footer-section.css";
import GitHub from "../../assets/icons/github-icon.svg";
import Linkedin from "../../assets/icons/linkedin-icon.svg";
import Dribbble from "../../assets/icons/dribbble-icon.svg";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-desktop">
        <a href="">
          <p>Linkedin</p>
        </a>
        <a href="">
          <p>Dribbble</p>
        </a>
        <a href="">
          <p>Github</p>
        </a>
        <a href="">
          <p>emilyrichardsdesign@gmail</p>
        </a>
        <p>© 2025</p>
      </div>
      <div className="footer-mobile">
        <div className="socials-mobile">
          <a href="">
            <p>Email</p>
          </a>
          <a href="">
            <p>Linkedin</p>
          </a>
          <a href="">
            <p>Dribbble</p>
          </a>
          <a href="">
            <p>Github</p>
          </a>
        </div>
        <p>© 2025</p>
      </div>
    </div>
  );
}
