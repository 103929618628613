import React, { useEffect } from "react";
import "./hero.css";
import Headshot from "../../assets/images/headshot.png";

export default function HeroSection() {
  useEffect(() => {
    const topHeading = document.querySelector(".hero-heading-top h1");
    const bottomHeading = document.querySelector(".hero-heading-bottom h1");

    if (!topHeading || !bottomHeading) {
      console.error("One or more elements not found:");
      console.error("topHeading:", topHeading);
      console.error("bottomHeading:", bottomHeading);
      return;
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      topHeading.style.transform = `translateX(${-scrollPosition * 0.5}px)`;
      bottomHeading.style.transform = `translateX(${scrollPosition * 0.5}px)`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="hero-section">
      <div className="hero-heading">
        <div className="hero-heading-top">
          <h1>UI/UX</h1>
          <div className="hero-right hero-description-desktop">
            <img src={Headshot} alt="/"></img>
            <p className="p-large right-align">
              Hello! I’m Emily, a UI/UX Designer with 5+ years experience based
              in Wales, UK 🏴󠁧󠁢󠁷󠁬󠁳󠁿
            </p>
          </div>
        </div>
        <div className="hero-heading-bottom">
          <h1 className="right-align">Designer</h1>
        </div>
        <div className="hero-description-mobile">
          <img src={Headshot} alt="/"></img>
          <h4 className="p-large">
            Hello! I’m Emily, a UI/UX Designer with 5+ years experience based in
            Wales, UK 🏴󠁧󠁢󠁷󠁬󠁳󠁿
          </h4>
        </div>
      </div>
    </div>
  );
}
