import React from "react";

import FadeInSection from "../animations/fade-in";
import Navigation from "../components/nav/nav";
import HeroSection from "../components/hero/hero";
import LeftTextSection from "../components/text-section/left-text-section";
import Skills from "../components/skills-section/skills";
import FeatureProjects from "../components/feature-projects/feature-projects";
import About from "../components/about-section/about";
import Footer from "../components/footer-section/footer-section";
import BackgroundGradient from "../components/background-gradient/background-gradient";

import AboutImage from "../assets/images/about-image.png";

export default function Home() {
  return (
    <div className="portfolio">
      <BackgroundGradient />
      <div className="main-content">
        <Navigation />
        <FadeInSection>
          <HeroSection />
        </FadeInSection>

        <FadeInSection>
          <LeftTextSection text="Welcome to my portfolio - a collection of UI/UX design, branding, web and CMS projects, XR experiences, and a hint of 3D work. I merge creativity with strategy, with an expanding focus on development and leveraging emerging technologies like AI." />
        </FadeInSection>

        <FadeInSection>
          <Skills />
        </FadeInSection>
        <FeatureProjects />
        <FadeInSection>
          <About
            image={AboutImage}
            title="Hello! I’m Emily Richards - a creative soul who thrives on challenges and loves to find ways to make a meaningful impact, big or small."
            body="When I’m not deep into design or exploring new skills, you’ll often find me hiking the Welsh mountains with my dogs, playing video games or discovering my next creative hobby. I love connecting with people - whether it’s to collaborate, learn from each other, or simply share a good conversation, so If you’re looking for someone who combines creativity, positivity, and good vibes (including the occasional dog cameo on Zoom), I’d love to hear from you!"
          />
        </FadeInSection>
        <Footer />
      </div>
    </div>
  );
}
