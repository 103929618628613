import React from "react";
import "./left-text-section.css";

export default function LeftTextSection(props) {
  return (
    <div className="left-text-section">
      <h2 className="left-side-text">{props.text}</h2>
    </div>
  );
}
