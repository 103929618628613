import React from "react";
import "./skills.css";
import UiIcon from "../../assets/icons/ui-icon.svg";
import BrandIcon from "../../assets/icons/brand-icon.svg";
import DeveloperIcon from "../../assets/icons/development-icon.svg";
import CubeIcon from "../../assets/icons/3d-icon.svg";
import IllustrationIcon from "../../assets/icons/illustration-icon.svg";

export default function Skills() {
  return (
    <div className="skills">
      <p className="Pill">What I do</p>
      <ul>
        <li>
          <img src={UiIcon} alt=""></img>
          <h4>UI/UX Design</h4>
        </li>
        <li>
          <img src={BrandIcon} alt=""></img>
          <h4>Branding</h4>
        </li>
        <li>
          <img src={DeveloperIcon} alt=""></img>
          <h4>Front-End Development</h4>
        </li>
        <li>
          <img src={CubeIcon} alt=""></img>
          <h4>3D Design</h4>
        </li>
        <li>
          <img src={IllustrationIcon} alt=""></img>
          <h4>Illustration</h4>
        </li>
      </ul>
    </div>
  );
}
