import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home-page";
import Projects from "./pages/projects-page";
import WeatherApp from "./pages/weather-app-page";
import WorldClock from "./pages/world-clock-page";
import LandingPage from "./pages/landing-page";
import FitnessWebsite from "./pages/responsive-fitness-website";
import { newtonsCradle } from "ldrs";

newtonsCradle.register();

export default function Portfolio() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <l-newtons-cradle size="80" speed="1" color="white"></l-newtons-cradle>
      </div>
    );
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/weather-app" element={<WeatherApp />} />
        <Route path="/world-clock" element={<WorldClock />} />
        <Route
          path="/responsive-fitness-website"
          element={<FitnessWebsite />}
        />
      </Routes>
    </div>
  );
}
